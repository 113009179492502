import React from "react";
import { Link } from "gatsby";
import FacebookBadge from "../../components/FacebookBadge";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import { faClock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArticleSubTitle from "../../components/ArticleSubTitle";
import "./articles.css";

//Import At least four Images for the Article
import articleImage_1 from "../../images/services/Savente-Consulting-Limited-Services-01.jpg";
import articleImage_2 from "../../images/social-media-images/SL-120219-25650-11 [Converted]-03.jpg";
import articleImage_3 from "../../images/social-media-images/SL-120219-25650-11 [Converted]-01.jpg";
import articleImage_4 from "../../images/social-media-images/SL-120219-25650-11 [Converted]-04.jpg";

const articleDetails = {
  title: "Clinical Trials & Contract Research In Nigeria",
  date: "22nd June, 2021",
  datePublished: "2021-22-22T08:00:00+08:00", // Y-M-d
  dateModified: "2021-24-22T08:00:00+08:00", //
  author: "Savante Consulting Limited",
  supurb:
    "Clinical trials are critical for identifying novel therapies for diseases and new techniques to detect, diagnose, and prevent disease development. In addition, clinical trials may demonstrate to researchers what works and doesn't work in people, which isn't possible to discover in the lab or with animals.",
};
const images = [
  {
    image: articleImage_1,
    alt: "Face Painting",
    key: 1,
  },
  {
    image: articleImage_2,
    alt: "Face Painting",
    key: 2,
  },
  {
    image: articleImage_3,
    alt: "Face Painting",
    key: 3,
  },
  {
    image: articleImage_4,
    alt: "Face Painting",
    key: 4,
  },
];

const listItems = images.map((image) => (
  <div key={image.key} className="col s12 l3 hide-on-small-only">
    <img src={image.image} className="responsive-img" alt={image.alt} />
  </div>
));

export default function Partnerships() {
  return (
    <Layout>
      <article>
        <SEO
          title={articleDetails.title}
          description={articleDetails.supurb}
          image={articleImage_2}
        />
        <Helmet>
          <script type="application/ld+json">
            {`                    
                      {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "` +
              articleDetails.title +
              `",
                        "image": [
                          "` +
              articleImage_1 +
              `",
                          "` +
              articleImage_2 +
              `",
                          "` +
              articleImage_3 +
              `",
                          "` +
              articleImage_4 +
              `",
                         ],
                        "datePublished": "` +
              articleDetails.datePublished +
              `",
                        "dateModified": "` +
              articleDetails.dateModified +
              `"
                      }
                  
                  
                `}
          </script>
        </Helmet>
        <br />
        <br />
        <div className="container article">
          <h1 className="title secondary-color-dark-text mb-5">
            {articleDetails.title}
          </h1>
          <Grid container>
            <Grid item xs={12}>
              <time className="pr-10">
                <FontAwesomeIcon
                  className="mr-8"
                  style={{ width: "1.125em" }}
                  aria-label="Mute volume"
                  icon={faClock}
                />
                <span>{articleDetails.date}</span>
              </time>
              <time>
                <FontAwesomeIcon
                  className="mr-8"
                  style={{ width: "1.125em" }}
                  aria-label="Mute volume"
                  icon={faUser}
                />
                <span>{articleDetails.author}</span>
              </time>
            </Grid>
          </Grid>
          <div className="row">
            <div className="col s12 l8 article-body">
              <Link
                to="/contact-us"
                className="btn btn-small waves-effect waves-light  getqouteButton hide-on-large-only  mb-10 mt-10"
                style={{ width: "100%" }}
              >
                Contact Us
              </Link>

              <div class="justify">
                <ArticleSubTitle subtitle="Why Are Clinical Trials Necessary?" />

                <p>
                  Clinical trials are critical for identifying novel therapies
                  for diseases and new techniques to detect, diagnose, and
                  prevent disease development. In addition, clinical trials may
                  demonstrate to researchers what works and doesn't work in
                  people, which isn't possible to discover in the lab or with
                  animals.
                </p>

                <ArticleSubTitle subtitle="Best Clinical Trial Research Services in Nigeria" />

                <p>
                  Savante Consulting is also one of Nigeria's premier clinical
                  research firms. Savante Consulting Limited assists firms in
                  the pharmaceutical, biotechnology, medical device, and food
                  and beverage industries with product registration and
                  regulatory clearances. Phase III and IV clinical trials will
                  be project-managed, and Pharmacovigilance Services will be
                  provided to support Marketing Authorization Applications.
                </p>

                <ArticleSubTitle subtitle="Preclinical Development and Clinical Trials" />

                <p>
                  Clinical research services for multi-phase studies are
                  provided by Savante Consulting Limited, emphasizing early
                  development. Savante Consulting is ideally positioned to
                  assist you plan, organize, and implement clinical trial
                  solutions for your specific needs as a top Nigerian contract
                  research firm with over 10 years of expertise in clinical
                  research.
                </p>

                <ArticleSubTitle subtitle="Relevant Expertise" />

                <p>
                  Before being accepted as an investigator in research, Savante
                  Consulting regulatory agencies demand that investigators get
                  formal training on ICH GCP standards. The Association for Good
                  Clinical Practices in Nigeria (AGCPN) recently launched a
                  certification program that would help to enhance awareness and
                  comprehension of ICH GCPs. Hundreds of clinical research
                  experts have been trained in Nigeria, with many more prepared
                  in the coming years.
                </p>
              </div>
            </div>
            <div className="col s12 l4 p-0 center">
              <Link
                to="/contact-us"
                className="btn btn-small waves-effect waves-light hide-on-small-only contact-us-btn"
                style={{ width: "100%" }}
              >
                Contact Us
              </Link>
              <br />
              <br />
              <FacebookBadge src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsavanteconsultingofficial%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" />
              <br />
              <iframe
                title="Google Badge"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5355607532397!2d3.387330214770342!3d6.45361289533113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b568f3f5fc1%3A0xb2c59461ac52d3b7!2sSavante%20Consulting%20Limited!5e0!3m2!1sen!2sru!4v1615677592874!5m2!1sen!2sru"
                allowFullScreen=""
                width="340"
                height="300"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
}
