import React from "react";

export default class ArticleSubTitle extends React.Component {
  render() {
    return (
      <h5>
        <strong>
          <span data-preserver-spaces="true">{this.props.subtitle}</span>
        </strong>
      </h5>
    );
  }
}
